import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {
  IgxActionStripModule,
  IgxButtonModule,
  IgxCardModule,
  IgxCheckboxModule,
  IgxChipsModule,
  IgxComboModule,
  IgxDateRangePickerModule,
  IgxDialogModule,
  IgxExcelExporterService,
  IgxGridModule,
  IgxHierarchicalGridModule,
  IgxIconModule,
  IgxInputGroupModule,
  IgxLayoutModule,
  IgxRadioModule,
  IgxRippleModule,
  IgxSnackbarModule,
  IgxSwitchModule,
  IgxTabsModule,
  IgxToastModule,
} from '@infragistics/igniteui-angular';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AuthGuardPageService } from '../shared/authen/auth-guard-page.service';
import { AuthGuardService } from '../shared/authen/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { AuthenscreenComponent } from './authenscreen/authenscreen.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { NotAvailableComponent } from './not-available/not-available.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageRoutingModule } from './page-routing.module';
import { PageComponent } from './page.component';
import { AuthenCompletedComponent } from './authen-completed/authen-completed.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxLoadingModule } from 'ngx-loading';


@NgModule({
  declarations: [
    NotAvailableComponent,
    HomePageComponent,
    NotFoundComponent,
    AuthenscreenComponent,
    PageComponent,
    NotAuthorizedComponent,
    AuthenCompletedComponent,
    ResetPasswordComponent,
  ],
  imports: [
    FormsModule,
    MaterialFileInputModule,
    MatFormFieldModule,
    IgxCheckboxModule,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    NgxSpinnerModule,
    PageRoutingModule,
    IgxGridModule,
    IgxHierarchicalGridModule,
    IgxDialogModule,
    IgxButtonModule,
    IgxRippleModule,
    IgxSnackbarModule,
    IgxToastModule,
    IgxActionStripModule,
    IgxSwitchModule,
    IgxLayoutModule,
    IgxChipsModule,
    IgxComboModule,
    IgxCardModule,
    IgxToastModule,
    IgxIconModule,
    IgxInputGroupModule,
    IgxRadioModule,
    IgxDateRangePickerModule,
    IgxTabsModule,
    NgxLoadingModule.forRoot({}),
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    GoogleMapsModule,
  ],
  providers: [AuthGuardService,AuthGuardPageService,IgxExcelExporterService],
  exports: [HomePageComponent, SharedModule],
})
export class PageModule {}

