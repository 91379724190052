import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environments } from 'src/app/page/shared/variable-share';
import { menuweb } from './../../page/shared/menuweb';

import { Title } from "@angular/platform-browser";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthGuardService {
  translate: TranslateService;
  constructor(private titleService: Title, private http: HttpClient, private router: Router, translate: TranslateService) {
    this.translate = translate;
  }
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.authenGuard(state);

  }

  authenGuard(state): Observable<boolean> {
    return this.http.get('api/Authen/session').pipe(
      map(session => {
        this.titleService.setTitle("โปรแกรมบัญชี AccCloud");
        sessionStorage.clear();
        sessionStorage.setItem('session', JSON.stringify(session));
        var userInSessionStorage = JSON.parse(JSON.stringify(session));
        var userInLocalStorage = JSON.parse(localStorage.getItem("info"));
        /* this.http.get("https://api.ipify.org/?format=json").subscribe(async (res: any) => {
         let checkIp={
           employeeMasterId:userInSessionStorage.employeeMasterId,
           fixIp: res.ip
         };
         let checkIpUserResult = await this.http.post<any>("api/Company/checkIpUser", checkIp).toPromise();
         if(checkIpUserResult.responseData != undefined && checkIpUserResult.responseData != null){
           if(checkIpUserResult.responseData == "N" || userInLocalStorage.superAdmin == 'stradmin' || userInLocalStorage.superAdmin == 'ACCOUNTTEST'){ */
        this.changeLanguage(userInSessionStorage.currentLang, userInSessionStorage.tblUserLoginId, session);
        if (userInLocalStorage.superAdmin != 'stradmin' && userInLocalStorage.superAdmin != 'ACCOUNTTEST') {
          var cookies = JSON.parse(localStorage.getItem("cookies"));
          if (cookies) {
            this.checkCookie(userInSessionStorage, cookies);
          } else {
            alert('ไม่พบคุกกี้');
            this.router.navigate(['login']);
          }
        }
        if (userInSessionStorage.acccloudStatus == 'N') {
          if (userInSessionStorage.payrollStatus == 'Y') {
            this.router.navigate(['payroll/calendar-leave']);
          } else {
            this.router.navigate(['login']);
          }
        }
        if (state.url == "/") {
          this.router.navigate(['homepage']);
        } else {
          var url = state.url.toString().split('/');
          var urlsystem = url[1].split('-').join('');
          var pageMenu = url[1];
          var page = url[1].split('-').join('');
          if (url[1] == "setup") {
            console.log("Start");
            console.log(url);
            console.log(urlsystem);
            console.log(state.url.toString().includes('transaction'));
            pageMenu = '';
            pageMenu = url[url.length - 1];//Not Transaction
            var urlpage = url[url.length - 1].split('-');
            if (pageMenu.includes('transaction')) {

            } else {
              if (state.url.toString().includes('transaction')) {//Transaction
                pageMenu = url[url.length - 2];
                urlpage = url[url.length - 2].split('-');
              }
            }

            page = urlpage.join('');
            var pageUpdate = pageMenu.split(';');
            pageMenu = pageUpdate[0];
            if (pageMenu.includes('transaction')) {
              pageMenu = pageMenu.split('transaction')[0].slice(0, pageMenu.lastIndexOf("-"));
              page = pageMenu.split('-').join('');
            }
            console.log(pageMenu);
            console.log(page);
            console.log("End");
          }
          else if (url.length > 3 && urlsystem == "pos") {
            console.log('first', url.length)
            pageMenu = '';
            pageMenu = url[3];
            var urlpage = url[3].split('-');
            page = urlpage.join('');
            var pageUpdate = pageMenu.split(';');
            pageMenu = pageUpdate[0];
            if (pageMenu.includes('transaction')) {
              pageMenu = pageMenu.split('transaction')[0].slice(0, pageMenu.lastIndexOf("-"));
              page = pageMenu.split('-').join('');
            }
          }
          else if (url.length > 2) {
            pageMenu = '';
            pageMenu = url[2];
            var urlpage = url[2].split('-');
            page = urlpage.join('');
            var pageUpdate = pageMenu.split(';');
            pageMenu = pageUpdate[0];
            if (pageMenu.includes('transaction')) {
              pageMenu = pageMenu.split('transaction')[0].slice(0, pageMenu.lastIndexOf("-"));
              page = pageMenu.split('-').join('');
            }
          }
          var system = urlsystem;
          if (system == 'report') {
            system = 'report/' + url[2];
            pageMenu = url[3];
            page = pageMenu.split('-').join('');
          }
          var authenFilter = [];
          if (menuweb[page] != undefined) {
            menuweb[page].forEach(element => {
              authenFilter.push({ tblUserLoginId: userInSessionStorage.tblUserLoginId, serviceMenu: element.system, pageMenu: element.page, page: element.page.split('-').join(''), companyCode: userInSessionStorage.companyCode });
            });
          } else {
            authenFilter.push({ tblUserLoginId: userInSessionStorage.tblUserLoginId, serviceMenu: system, pageMenu: pageMenu, page: page, companyCode: userInSessionStorage.companyCode });
          }
          this.http.post<any>("api/Login/authenSystem/getAuthenPage", authenFilter).subscribe(data => {
            if (data != undefined && data != null && data.length != 0) {
              if (data.length == 1) {
                switch (userInSessionStorage.currentLang) {
                  case 'en-US':
                    this.titleService.setTitle(data[0].menuEName + " | โปรแกรมบัญชี AccCloud");
                    break;
                  default:
                    this.titleService.setTitle(data[0].menuTName + " | โปรแกรมบัญชี AccCloud");
                    break;
                }
              }
              data.forEach(element => {
                if (userInSessionStorage.userAuthen != "Y" || userInLocalStorage.superAdmin == 'stradmin') {
                  element.asRead = true;
                  element.asWrite = true;
                  element.asDelete = true;
                  element.asEdit = true;
                  element.asPrint = true;
                }
                if (userInLocalStorage.superAdmin == 'stradmin' || environments.environments == "local") {
                  element.available = true;
                }
                sessionStorage.setItem(element.page, JSON.stringify(element));
              });
            } else {
              if (userInLocalStorage.superAdmin != 'stradmin' && userInLocalStorage.superAdmin != 'ACCOUNTTEST' && urlsystem != "report" && urlsystem != "calendar") {
                alert('ERR:S002 มีปัญหากรุณาติดต่อทาง Support');
                this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate(["login"]));
              } else {
                var authenSystem = {
                  asRead: true,
                  asWrite: true,
                  asDelete: true,
                  asEdit: true,
                  asPrint: true,
                  available: true,
                  companyAutoApprove: "A"
                };
                sessionStorage.setItem('authen' + authenFilter[0].page, JSON.stringify(authenSystem));
              }
            }
            /* else {
             var authenSystem = {
               asRead: true,
               asWrite: true,
               asDelete: true,
               asEdit: true,
               asPrint: true,
               available: true,
               companyAutoApprove: "A"
             };
             sessionStorage.setItem('authen' + authenFilter[0].page, JSON.stringify(authenSystem));
           } */
          });
        }
        /* }else{
          alert('เข้าใช้งานได้เฉพาะ IP ที่กำหนด');
          this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate(["login"]));
        }
      }else{
        alert('ERR:AU001 มีปัญหากรุณาติดต่อทาง Support');
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate(["login"]));
      }
    }); */
        return true;
      }),
      catchError((err) => {
        var value = JSON.parse(localStorage.getItem("re-check"));
        if (value) {
          this.http.post<any>("api/authen/login", value).subscribe(async (relogin) => {
            if (relogin == "1") {
              if (state.url != "/homepage") {
                this.router.navigate(['/homepage']);
              } else {
                this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate(["/homepage"]));
              }
            }
          });
        } else {
          alert('ไม่สามารถเรียก Session ได้');
          this.router.navigate(['login']);
        }
        return of(true);
      })
    );
  }

  changeLanguage(lang: string, id, session) {
    this.http.post<any>("api/TblUserLogin/getById", { tblUserLoginId: id }).subscribe((TblUserLoginData) => {
      this.translate.use(TblUserLoginData[0].currentLang);
      /* var userInSessionStorage = JSON.parse(JSON.stringify(session));
      console.log(userInSessionStorage);
      if(userInSessionStorage.currentLang != TblUserLoginData[0].currentLang){
        userInSessionStorage.currentLang = TblUserLoginData[0].currentLang;
        sessionStorage.setItem('session', JSON.stringify(userInSessionStorage));
      } */
    });
  }

  async checkCookie(filter, cookies) {
    let cookieGet = await this.http.post<any>("api/Login/Authentication/CheckCookies", { tblUserLoginId: filter.tblUserLoginId }).toPromise();
    if (cookies != cookieGet[0].cookies) {
      await this.http.post<any>("api/Login/Authentication/SetCookies", { tblUserLoginId: filter.tblUserLoginId, companyCode: filter.companyCode, cookieOld: cookies, cookieNew: cookieGet[0].cookies }).toPromise();
      alert('รหัสพนักงานมีการเข้าใช้งานซ้อน');
      this.router.navigate(['login']);
    }
  }
}
