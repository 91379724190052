<div class="content-wrap-sidenav" igxLayout>
  <igx-nav-drawer id="navigation" #drawer [isOpen]="false" [pin]="true" [pinThreshold]="0">
    <ng-template igxDrawer>
      <div class="imgg" igxToggleAction="navigation" (click)="closeSideNav()">
        <img class="img" src="assets//media/logos/acccloud-logo.png" />
      </div>


      <div class="DashBoard">
        <igx-expansion-panel class="my-expansion-panel" [(collapsed)]="collapseModel.dashboard">
          <igx-expansion-panel-header (onInteraction)="onInteraction($event)" [disabled]="false"
            [iconPosition]="'right'">
            <igx-expansion-panel-title class="sample-title">
              <igx-icon class="sample-title-icon " fontSet="material">dashboard</igx-icon>
              <div [translate]>Dashboards</div>
            </igx-expansion-panel-title>
          </igx-expansion-panel-header>
          <igx-expansion-panel-body>
            <div>
              <span igxDrawerItem igxRipple *ngFor="let item of dashboard" (click)="onClick(item.navigation)">
                <span class="margin">
                  <div [translate] class="cut-text-multi">{{item.text}}
                  </div>
                </span>
              </span>
            </div>
          </igx-expansion-panel-body>
        </igx-expansion-panel>
      </div>
      <igx-divider></igx-divider>
      <igx-expansion-panel class="my-expansion-panel" [(collapsed)]="collapseModel.companyProfile">
        <igx-expansion-panel-header (onInteraction)="onInteraction($event)" [disabled]="false" [iconPosition]="'right'">
          <igx-expansion-panel-title class="sample-title">
            <igx-icon class="sample-title-icon" fontSet="material">apartment</igx-icon>
            <div [translate]>CompanyProfile</div>
          </igx-expansion-panel-title>
        </igx-expansion-panel-header>
        <igx-expansion-panel-body>

          <igx-expansion-panel class="my-expansion-panel">

            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  CompanyInformation
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>

            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of CompanyInformation"
                  (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div style="padding-top: 0px; margin-top: 0px;" [translate] class="cut-text-multi">{{ item.text }}
                    </div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  UserProfile
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of UserProfile" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
              <igx-expansion-panel class="my-expansion-panel">
                <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
                  <igx-expansion-panel-title class="sample-title">
                    <div class="igx-expansion-header-title" [translate]>
                      UserAuthorization
                    </div>
                  </igx-expansion-panel-title>
                </igx-expansion-panel-header>

                <igx-expansion-panel-body>
                  <div>
                    <span igxDrawerItem igxRipple (click)="onClick('setup/userautho/changepassword')">
                      <span class="margin">
                        <div style="padding-top: 0px; margin-top: 0px;" [translate] class="cut-text-multi">
                          ChangePassword
                        </div>
                      </span>
                    </span>
                    <span igxDrawerItem igxRipple (click)="onClick('setup/userautho/authenbysystem')">
                      <span class="margin">
                        <div style="padding-top: 0px; margin-top: 0px;" [translate] class="cut-text-multi">AuthenSystem
                        </div>
                      </span>
                    </span>
                    <span igxDrawerItem igxRipple (click)="onClick('setup/userautho/authorization-by-user')">
                      <span class="margin">
                        <div style="padding-top: 0px; margin-top: 0px;" [translate] class="cut-text-multi">
                          AuthenEmployee
                        </div>
                      </span>
                    </span>
                    <span igxDrawerItem igxRipple (click)="onClick('setup/userautho/InputMinMax')">
                      <span class="margin">
                        <div style="padding-top: 0px; margin-top: 0px;" [translate] class="cut-text-multi"> InputMinMax
                        </div>
                      </span>
                    </span>
                  </div>
                </igx-expansion-panel-body>
              </igx-expansion-panel>

            </igx-expansion-panel-body>
          </igx-expansion-panel>



        </igx-expansion-panel-body>
      </igx-expansion-panel>
      <igx-divider></igx-divider>
      <igx-expansion-panel class="my-expansion-panel" [(collapsed)]="collapseModel.setupProfile">
        <igx-expansion-panel-header (onInteraction)="onInteraction($event)" [disabled]="false" [iconPosition]="'right'">
          <igx-expansion-panel-title class="sample-title">
            <igx-icon class="sample-title-icon" fontSet="material">settings</igx-icon>
            <div [translate]>SetupProfile</div>
          </igx-expansion-panel-title>
        </igx-expansion-panel-header>
        <igx-expansion-panel-body>

          <igx-expansion-panel class="my-expansion-panel">

            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  SetBookAcc
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>

            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of SetupAccount" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div style="padding-top: 0px; margin-top: 0px;" [translate] class="cut-text-multi">{{ item.text }}
                    </div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  ProductInfo
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Product" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  ServiceInfo
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Service" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  PurchaseSystem
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Purchase" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  SaleSystem
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Sale" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  TransportSystem
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Transport" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>SaleArea</div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of SaleArea" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  bankingsystem
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Bank" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  BoqSystem
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Boq" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>JobInfo</div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div *ngIf="perpetualMode != 'Y'">
                <span igxDrawerItem igxRipple *ngFor="let item of Job" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
              <div *ngIf="perpetualMode == 'Y'">
                <span igxDrawerItem igxRipple *ngFor="let item of JobForPerpetual" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  TaxSystemSetup
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Tax" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title cut-text-multi" [translate]>
                  NationalCurrencey
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Currencey" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  ImportData
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Import" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>
        </igx-expansion-panel-body>
      </igx-expansion-panel>
      <igx-divider></igx-divider>
      <igx-expansion-panel class="my-expansion-panel" [(collapsed)]="collapseModel.reportAndAnalysis">
        <igx-expansion-panel-header (onInteraction)="onInteraction($event)" [disabled]="false" [iconPosition]="'right'">
          <igx-expansion-panel-title class="sample-title">
            <igx-icon class="sample-title-icon" fontSet="material">description</igx-icon>
            <div [translate]>Report</div>
          </igx-expansion-panel-title>
        </igx-expansion-panel-header>
        <igx-expansion-panel-body>
          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  CompanyInformation
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of report" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  SetupProfile
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of setupprofile" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  RevenueDepartmentReport
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of revenuedepartmentreport"
                  (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  AccountReceivableReport
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of accountReceivablereport"
                  (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  Tradeaccountpayables
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of tradeaccountpayables"
                  (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  PurchaseSystem
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of PurchaseSystemProfile"
                  (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  SaleSystem
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of SaleSystemProfile" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  FN09
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of FN09" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  FN02
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of FN02" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  InventorySystem
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of InventorySystem" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
              <div *ngIf="perpetualMode == 'Y' ">
                <span igxDrawerItem igxRipple *ngFor="let item of InventorySystemForPerpetual" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  RIV32
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div *ngIf="perpetualMode != 'Y'">
                <span  igxDrawerItem igxRipple *ngFor="let item of ProcessSystem" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
              <div *ngIf="perpetualMode == 'Y'">
                <span  igxDrawerItem igxRipple *ngFor="let item of ProcessSystemForPerpetual" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  AccountSystem
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of AccountSystem" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  ChequeSystem
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of ChequeSystem" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  SystemAsset
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of SystemAsset" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>

          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  Log Tracing
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of LogTracing" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>
        </igx-expansion-panel-body>
      </igx-expansion-panel>

      <igx-divider></igx-divider>
      <div class="Analysis">
        <igx-expansion-panel class="my-expansion-panel" [(collapsed)]="collapseModel.analysis">
          <igx-expansion-panel-header (onInteraction)="onInteraction($event)" [disabled]="false"
            [iconPosition]="'right'">
            <igx-expansion-panel-title class="sample-title">
              <igx-icon class="sample-title-icon " fontSet="material">analytics</igx-icon>
              <div [translate]>Analysis</div>
            </igx-expansion-panel-title>
          </igx-expansion-panel-header>
          <igx-expansion-panel-body>
            <div>
              <span igxDrawerItem igxRipple *ngFor="let item of reportanalysis" (click)="onClick(item.navigation)">
                <span class="margin">
                  <div [translate] class="cut-text-multi">{{item.text}}
                  </div>
                </span>
              </span>
            </div>
          </igx-expansion-panel-body>
        </igx-expansion-panel>
      </div>

      <igx-divider></igx-divider>
      <div class="Extension">
        <igx-expansion-panel class="my-expansion-panel" [(collapsed)]="collapseModel.extension">
          <igx-expansion-panel-header (onInteraction)="onInteraction($event)" [disabled]="false"
            [iconPosition]="'right'">
            <igx-expansion-panel-title class="sample-title">
              <igx-icon class="sample-title-icon " fontSet="material">extension</igx-icon>
              <div [translate]>Extension</div>
            </igx-expansion-panel-title>
          </igx-expansion-panel-header>
          <igx-expansion-panel-body>
            <div>
              <span igxDrawerItem igxRipple *ngFor="let item of extension" (click)="onClick(item.navigation)">
                <span  class="margin">
                  <div  [translate] class="cut-text-multi">{{item.text}}
                  </div>
                </span>
              </span>
            </div>
          </igx-expansion-panel-body>
        </igx-expansion-panel>
      </div>

      <igx-divider></igx-divider>

      <igx-expansion-panel class="my-expansion-panel" [(collapsed)]="collapseModel.support">
        <igx-expansion-panel-header (onInteraction)="onInteraction($event)" [disabled]="false" [iconPosition]="'right'">
          <igx-expansion-panel-title class="sample-title">
            <igx-icon class="sample-title-icon" fontSet="material">contact_support</igx-icon>
            <div [translate]>Help</div>
          </igx-expansion-panel-title>
        </igx-expansion-panel-header>
        <igx-expansion-panel-body>

          <!-- <div>
            <span igxDrawerItem igxRipple *ngFor="let item of support" (click)="onClick(item.navigation)">
              <span class="margin">
                <div [translate] class="cut-text-multi">{{ item.text }}</div>
              </span>
            </span>
          </div> -->


          <!-- <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  Manual
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of Manual" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel> -->
          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  FAQ
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of faq" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>
          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  VDO
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of vdo" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>
          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  businessType
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of businessType" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>
          <igx-expansion-panel class="my-expansion-panel">
            <igx-expansion-panel-header [disabled]="false" [iconPosition]="'right'">
              <igx-expansion-panel-title class="sample-title">
                <div class="igx-expansion-header-title" [translate]>
                  Document
                </div>
              </igx-expansion-panel-title>
            </igx-expansion-panel-header>
            <igx-expansion-panel-body>
              <div>
                <span igxDrawerItem igxRipple *ngFor="let item of document" (click)="onClick(item.navigation)">
                  <span class="margin">
                    <div [translate] class="cut-text-multi">{{ item.text }}</div>
                  </span>
                </span>
              </div>
            </igx-expansion-panel-body>
          </igx-expansion-panel>


        </igx-expansion-panel-body>
      </igx-expansion-panel>
      <!-- <span igxDrawerItem igxRipple (click)="onClick('V.1')">
      <span class="sample-title">
        <div [translate]>Back To V.1</div>
      </span>
    </span> -->
      <span *ngIf="superAdmin" igxDrawerItem igxRipple (click)="onClick('center/super-admin')">
        <span class="sample-title">
          <div>Super Admin</div>
        </span>
      </span>
    </ng-template>

    <ng-template igxDrawerMini>
      <span *ngFor="let item of navItems" igxDrawerItem igxToggleAction="navigation" (click)="toggle(item.tag)">
        <igx-icon family="material">{{ item.name }}</igx-icon>
      </span>
    </ng-template>

  </igx-nav-drawer>
</div>